import { Button } from "@/components/ui/button";
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel";
import { cn } from "@/utils/cn";
import { forwardRef, useEffect, useState } from "react";
import { EllipsisPagination } from "./ellipsisPagination";
import { Clock } from "./icons/clock";
import { ForkAndPlate } from "./icons/forkAndPlate";
import { CampusPointeLogo } from "./icons/campusPointeLogo";
import { BookOpen } from "./icons/bookOpen";
import { WheelGesturesPlugin } from "embla-carousel-wheel-gestures";
import { Computer } from "./icons/computer";
import { Document } from "./icons/document";
import { Glasses } from "./icons/glasses";
import { Truck } from "./icons/truck";
import { DollarSign } from "./icons/dollarSign";
import campusPointe from "@/assets/images/campusPointe.png";
import FSUstatue from "@/assets/images/FSUstatue.jpeg";
import PresidentsBooth from "@/assets/images/presidents-booth.jpeg";
import guyLibrary from "@/assets/images/guy-library.png";
import LearningCenter from "@/assets/images/LearningCenter.png";
import CareerFair from "@/assets/images/CareerFair.png";
import Logo from "@/assets/brand/FresnoStateLogo.png";

export type TSuggestion = {
  id: number;
  lead: string;
  body: string;
};

type SuggestionsProps = {
  handleSuggestionClick: (text: string) => Promise<void>;
  className: string;
};

export const Suggestions = forwardRef<HTMLDivElement, SuggestionsProps>(
  ({ handleSuggestionClick, className }: SuggestionsProps, ref) => {
    const [api, setApi] = useState<CarouselApi>();
    const [current, setCurrent] = useState(0);
    const [count, setCount] = useState(0);

    useEffect(() => {
      if (!api) {
        return;
      }

      // Manage carousel pagination indicator
      setCount(api.scrollSnapList().length);
      setCurrent(api.selectedScrollSnap() + 1);

      api.on("select", () => {
        setCurrent(api.selectedScrollSnap() + 1);
      });
    }, [api]);

    return (
      <div
        className={cn(
          "tw-m-b-auto tw-flex tw-h-full tw-w-full tw-max-w-full tw-flex-col tw-items-end tw-space-y-2 tw-overflow-y-auto tw-overflow-x-hidden tw-px-5 tw-pb-32",
          className,
        )}
        ref={ref}
      >
        <div className="tw-sticky tw-top-[-1px] tw-z-40 tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-center tw-py-4">
          <div className="tw-bg-blur tw-absolute tw--left-5 tw-top-0 tw-z-10 tw-h-full tw-w-dvw tw-bg-gradient-to-b tw-from-transparent tw-to-transparent" />
          <img
            src={Logo}
            loading="lazy"
            className="tw-relative tw-z-20 tw-mr-2 tw-max-h-10"
            role="presentation"
          />
        </div>
        <div className="tw-max-w-chat tw-mt-auto tw-flex tw-w-full tw-flex-col tw-items-center tw-self-center tw-px-0">
          <h2 className="tw-my-10 tw-text-3xl tw-font-light tw-tracking-wide">
            Welcome!
            <br /> How Can I Help?
          </h2>
          <div className="tw-bg-bento tw-flex tw-h-fit tw-min-h-[25dvh] tw-w-full tw-flex-col tw-space-y-2 tw-overflow-hidden tw-rounded-[20px] tw-p-5 tw-text-sm tw-text-white md:tw-flex-row">
            <img
              src={FSUstatue}
              loading="lazy"
              className="tw-w-full tw-flex-shrink-0 tw-rounded-xl md:tw-w-7/12"
              role="presentation"
            />
            <p className="tw-text-wrap tw-px-1 tw-text-left tw-font-medium md:tw-self-center md:tw-px-4">
              Welcome to Bulldog Genie. Your friendly AI assistant ready to
              answer your Fresno State questions —anytime, anywhere!
              <span className="tw-underline">
                <a
                  href="https://fresnostate.kualibuild.com/app/677d75a5e1abda0155998e1c/start"
                  target="_blank"
                >
                  <br /> <br />
                  Click here to share feedback on how Bulldog Genie can better
                  assist you.
                </a>
              </span>
            </p>
          </div>
          {/* Carousel for mobile */}
          <div className="tw-relative tw-w-full md:tw-hidden">
            <Carousel
              setApi={setApi}
              className="tw-mt-10"
              plugins={[WheelGesturesPlugin()]}
            >
              <CarouselContent className="tw--ml-8">
                <CarouselItem className="tw-pl-8">
                  <DiningOptionsGroup
                    handleSuggestionClick={handleSuggestionClick}
                  />
                </CarouselItem>
                <CarouselItem className="tw-pl-8">
                  <CampusResourcesGroup
                    handleSuggestionClick={handleSuggestionClick}
                  />
                </CarouselItem>
                <CarouselItem className="tw-pl-8">
                  <AcademicSupportGroup
                    handleSuggestionClick={handleSuggestionClick}
                  />
                </CarouselItem>
                <CarouselItem className="tw-pl-8">
                  <StudentServicesGroup
                    handleSuggestionClick={handleSuggestionClick}
                  />
                </CarouselItem>
              </CarouselContent>
            </Carousel>
            <EllipsisPagination
              size={count}
              index={current}
              className="tw-mt-3"
            />
          </div>
          {/* Grid Layout for desktop */}
          <div className="tw-relative tw-z-0 tw-my-16 tw-hidden tw-w-full tw-grid-cols-2 tw-grid-rows-2 tw-gap-12 md:tw-grid">
            <DiningOptionsGroup handleSuggestionClick={handleSuggestionClick} />
            <CampusResourcesGroup
              handleSuggestionClick={handleSuggestionClick}
            />
            <AcademicSupportGroup
              handleSuggestionClick={handleSuggestionClick}
            />
            <StudentServicesGroup
              handleSuggestionClick={handleSuggestionClick}
            />
          </div>
          <div className="tw-bg-bento tw-mt-10 tw-flex tw-h-fit tw-min-h-[25dvh] tw-w-full tw-flex-col tw-space-y-2 tw-overflow-hidden tw-rounded-[20px] tw-p-0 tw-items-center tw-justify-center tw-whitespace-nowrap tw-text-sm tw-font-medium">
            <img
              src={PresidentsBooth}
              loading="lazy"
              className="tw-w-full"
              role="presentation"
            />
            <span className="tw-text-wrap tw-p-6 tw-pt-3 tw-text-left md:tw-text-base tw-decoration-1 tw-underline tw-self-start">
              <a
                href="https://campusnews.fresnostate.edu/october-14-2024/advancing-together-ai-campus-initiative"
                target="_blank"
              >
                Learn more about Fresno State's AI Campus Initiative here.
              </a>
            </span>
          </div>
          <p className="tw-mb-3 tw-mt-20 tw-text-xs">
            Bulldog Genie uses AI. Check for mistakes.
          </p>
        </div>
      </div>
    );
  },
);

function DiningOptionsGroup({
  handleSuggestionClick,
}: {
  handleSuggestionClick: (text: string) => void;
}) {
  return (
    <section className="tw-flex tw-h-72 tw-w-full tw-flex-col tw-items-center tw-justify-center">
      <h3 className="tw-mb-2 tw-ml-1 tw-self-start tw-text-xl tw-font-bold md:tw-self-center">
        Dining Options
      </h3>
      <div className="tw-flex tw-h-full tw-w-full tw-flex-row tw-justify-center tw-gap-4">
        <div className="tw-flex tw-h-full tw-w-full tw-flex-grow tw-flex-col tw-gap-4">
          <SuggestionBox
            handleSuggestionClick={handleSuggestionClick}
            prompt={"What time is the dining hall open?"}
            className="tw-bg-[#2F174B]"
          >
            <Clock
              className="tw-absolute tw-left-0 tw-top-0 tw-mr-2 tw-size-16 tw-text-[#1C0B31]"
              aria-hidden
            />
            <span>Dining Hall Hours</span>
          </SuggestionBox>
          <SuggestionBox
            handleSuggestionClick={handleSuggestionClick}
            prompt={"Where can I get food on campus?"}
            className="tw-bg-[#012D28]"
          >
            <ForkAndPlate
              className="tw-absolute tw-left-0 tw-top-0 tw-mr-2 tw-size-16 tw-text-[#001E1A]"
              aria-hidden
            />
            <span>On Campus Dining</span>
          </SuggestionBox>
        </div>
        <div className="tw-flex tw-h-full tw-w-full tw-flex-grow">
          <SuggestionBox
            handleSuggestionClick={handleSuggestionClick}
            prompt={"What food can I get at campus pointe?"}
          >
            <div
              className="tw-absolute tw-inset-0 tw-z-0 tw-bg-cover tw-bg-center tw-opacity-60 tw-rounded-[20px]"
              style={{
                backgroundImage: `url(${campusPointe})`,
              }}
            />
            <CampusPointeLogo
              className="tw-absolute tw-left-0 tw-top-0 tw-z-20 tw-size-16"
              aria-hidden
            />
            <span className="tw-relative tw-z-20">Campus Pointe</span>
          </SuggestionBox>
        </div>
      </div>
    </section>
  );
}

function CampusResourcesGroup({
  handleSuggestionClick,
}: {
  handleSuggestionClick: (text: string) => void;
}) {
  return (
    <section className="tw-flex tw-h-72 tw-w-full tw-flex-col tw-items-center tw-justify-center">
      <h3 className="tw-mb-2 tw-ml-1 tw-self-start tw-text-xl tw-font-bold md:tw-self-center">
        Campus Resources
      </h3>
      <div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-justify-center tw-gap-4">
        <div className="tw-flex tw-h-full tw-w-full tw-flex-grow">
          <SuggestionBox
            handleSuggestionClick={handleSuggestionClick}
            prompt={
              "What services are offered by the Student Health and Counseling Center?"
            }
          >
            <div
              className="tw-absolute tw-inset-0 tw-z-0 tw-bg-cover tw-bg-center tw-opacity-60 tw-rounded-[20px]"
              style={{
                backgroundImage: `url(${LearningCenter})`,
                backgroundPosition: "30% 80%",
              }}
            />
            <span className="tw-relative tw-z-10 tw-self-end">
              Student Health and Counseling Center
            </span>
          </SuggestionBox>
        </div>
        <div className="tw-flex tw-h-full tw-w-full tw-flex-grow tw-flex-row tw-gap-4">
          <SuggestionBox
            handleSuggestionClick={handleSuggestionClick}
            prompt={"How can I reach out to the Learning Center?"}
            className="tw-bg-[#012D28]"
          >
            <BookOpen
              className="tw-absolute tw--top-2 tw-left-0 tw-mr-2 tw-size-20 tw-text-[#001E1A]"
              aria-hidden
            />
            <span className="tw-relative tw-z-20">Learning Center</span>
          </SuggestionBox>
          <SuggestionBox
            handleSuggestionClick={handleSuggestionClick}
            prompt={"How can I reach out to the Career Development Center?"}
            className="tw-bg-[#2F174B]"
          >
            <Computer
              className="tw-absolute tw--top-2 tw-left-0 tw-mr-2 tw-size-20 tw-text-[#1C0B31]"
              aria-hidden
            />
            <span className="tw-relative tw-z-20">
              Career Development Center
            </span>
          </SuggestionBox>
        </div>
      </div>
    </section>
  );
}

function AcademicSupportGroup({
  handleSuggestionClick,
}: {
  handleSuggestionClick: (text: string) => void;
}) {
  return (
    <section className="tw-flex tw-h-72 tw-w-full tw-flex-col tw-items-center tw-justify-center">
      <h3 className="tw-mb-2 tw-ml-1 tw-self-start tw-text-xl tw-font-bold md:tw-self-center">
        Academic Support
      </h3>
      <div className="tw-flex tw-h-full tw-w-full tw-flex-row tw-justify-center tw-gap-4">
        <div className="tw-flex tw-h-full tw-w-full tw-flex-grow">
          <SuggestionBox
            handleSuggestionClick={handleSuggestionClick}
            prompt={"What services does the library have to offer?"}
          >
            <div
              className="tw-absolute tw-inset-0 tw-z-0 tw-bg-cover tw-opacity-60 tw-rounded-[20px]"
              style={{
                backgroundImage: `url(${guyLibrary})`,
                backgroundPosition: "30% 50%",
              }}
            />
            <span className="tw-relative tw-z-10 tw-self-end">
              Library Services
            </span>
          </SuggestionBox>
        </div>
        <div className="tw-flex tw-h-full tw-w-full tw-flex-grow tw-flex-col tw-gap-4">
          <SuggestionBox
            handleSuggestionClick={handleSuggestionClick}
            prompt={"Where are some quiet study areas on campus?"}
            className="tw-bg-[#2F174B]"
          >
            <Glasses
              className="tw-absolute tw--top-2 tw-left-0 tw-mr-2 tw-size-20 tw-text-[#1C0B31]"
              aria-hidden
            />
            <span className="tw-relative tw-z-20">Study Areas</span>
          </SuggestionBox>
          <SuggestionBox
            handleSuggestionClick={handleSuggestionClick}
            prompt={"How can the writing center help me with my coursework?"}
            className="tw-bg-[#012D28]"
          >
            <Document
              className="tw-absolute tw--left-1 tw--top-1 tw-mr-2 tw-size-20 tw-text-[#001E1A]"
              aria-hidden
            />
            <span className="tw-relative tw-z-20">Writing Center</span>
          </SuggestionBox>
        </div>
      </div>
    </section>
  );
}

function StudentServicesGroup({
  handleSuggestionClick,
}: {
  handleSuggestionClick: (text: string) => void;
}) {
  return (
    <section className="tw-flex tw-h-72 tw-w-full tw-flex-col tw-items-center tw-justify-center">
      <h3 className="tw-mb-2 tw-ml-1 tw-self-start tw-text-xl tw-font-bold md:tw-self-center">
        Student Services
      </h3>
      <div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-justify-center tw-gap-4">
        <div className="tw-flex tw-h-full tw-w-full tw-flex-grow tw-flex-row tw-gap-4">
          <SuggestionBox
            handleSuggestionClick={handleSuggestionClick}
            prompt={"Where can I park on campus?"}
            className="tw-bg-[#2F174B]"
          >
            <Truck
              className="tw-absolute tw--top-1 tw-left-0 tw-mr-2 tw-size-20 tw-text-[#1C0B31]"
              aria-hidden
            />
            <span className="tw-relative tw-z-20">Commute Info</span>
          </SuggestionBox>
          <SuggestionBox
            handleSuggestionClick={handleSuggestionClick}
            prompt={"What is the cost of attendance?"}
            className="tw-bg-[#012D28]"
          >
            <DollarSign
              className="tw-absolute tw--left-4 tw--top-1 tw-mr-2 tw-size-20 tw-text-[#001E1A]"
              aria-hidden
            />
            <span className="tw-relative tw-z-20">Financial Aid Guidance</span>
          </SuggestionBox>
        </div>
        <div className="tw-flex tw-h-full tw-w-full tw-flex-col">
          <SuggestionBox
            handleSuggestionClick={handleSuggestionClick}
            prompt={"What resources can I seek to help me advance my career?"}
          >
            <div
              className="tw-absolute tw-inset-0 tw-z-0 tw-bg-cover tw-opacity-60 tw-rounded-[20px]"
              style={{
                backgroundImage: `url('${CareerFair}')`,
                backgroundPosition: "right 20%",
              }}
            />
            <span className="tw-relative tw-z-10 tw-self-end">
              Career Services
            </span>
          </SuggestionBox>
        </div>
      </div>
    </section>
  );
}

type SuggestionBoxProps = {
  handleSuggestionClick: (message: string) => void;
  prompt: string;
} & React.HTMLAttributes<HTMLButtonElement>;

export function SuggestionBox({
  className,
  children,
  prompt,
  handleSuggestionClick,
  ...props
}: SuggestionBoxProps) {
  return (
    <Button
      variant="bento"
      className={cn(
        "tw-relative tw-flex tw-h-full tw-w-full tw-flex-col tw-justify-end tw-overflow-hidden tw-text-wrap tw-rounded-[20px] tw-bg-[#071A3D]",
        className,
      )}
      onClick={() => handleSuggestionClick(prompt)}
      {...props}
    >
      {children}
    </Button>
  );
}
