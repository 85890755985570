import { memo, useEffect, useRef, useState } from "react";
import Markdown from "@/components/ui/markdown";
import remarkBreaks from "remark-breaks";
import { TChatMessage } from "@/types/message";
import { ErrorMessage } from "@/components/ui/errorMessage";
import { LoadingText } from "../loadingText";
import { useIsMutating } from "@tanstack/react-query";
import { cn, debounce } from "@/utils";
import { useAutoScroll } from "@/hooks/useAutoScroll";
import { Button } from "../ui/button";
import { useParams } from "react-router-dom";
import { ArrowDown } from "lucide-react";
import { motion } from "motion/react";

const EmbeddedMessages = memo(
  ({
    messages,
    isLoading,
  }: {
    messages: TChatMessage[];
    isLoading: boolean;
  }) => {
    const { sessionId } = useParams();
    const isMutating = useIsMutating({ mutationKey: ["messages"] });
    const scrollContainer = useRef<HTMLDivElement>(null);
    const [scrollButtonToggled, setScrollButtonToggled] = useState(false);
    useAutoScroll(scrollContainer);

    // Hacky way of hiding scroll button when navigating to different conversation
    useEffect(() => {
      setScrollButtonToggled(false);
    }, [sessionId]);

    const handleScroll = debounce(() => {
      if (!scrollContainer.current) return;
      // Show button when user scrolls 25 pixels from the bottom of the chat
      if (
        scrollContainer.current.scrollTop <
        scrollContainer.current.scrollHeight -
          scrollContainer.current.clientHeight -
          25
      ) {
        setScrollButtonToggled(true);
      } else {
        setScrollButtonToggled(false);
      }
    }, 50);

    return (
      <motion.div
        className="no-scrollbar tw-mb-auto tw-flex tw-h-full tw-w-full tw-max-w-full tw-flex-col tw-items-start tw-space-y-3 tw-overflow-y-auto tw-overflow-x-hidden tw-pb-8 tw-text-sm tw-text-black"
        onScroll={handleScroll}
        ref={scrollContainer}
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.2 }}
      >
        {messages.map((message, index) => {
          // User Message
          return message.owner === "user" ? (
            <div
              className="tw-flex tw-w-full tw-place-content-center"
              key={message.id}
            >
              <div className="tw-max-w-chat tw-flex tw-w-full tw-justify-end tw-p-1">
                <div className="tw-bg-blue tw-rounded-message tw-shadow-input tw-flex tw-max-w-[90%] tw-flex-col tw-self-end tw-whitespace-pre-wrap tw-text-wrap tw-break-words tw-border tw-border-[rgba(43,62,94,0.50)] tw-p-4 tw-py-1.5 tw-text-left tw-text-xs tw-text-white">
                  <div>{message.message}</div>
                </div>
              </div>
            </div>
          ) : (
            // Bot Message
            <div
              className="tw-flex tw-w-full tw-place-content-center"
              key={message.id}
            >
              <div className="tw-max-w-chat tw-flex tw-w-full tw-flex-row tw-self-start tw-p-1">
                <div className="tw-shadow-input tw-border-gray tw-flex tw-min-w-0 tw-flex-col tw-text-wrap tw-break-words tw-rounded-br-lg tw-rounded-tl-lg tw-rounded-tr-lg tw-border tw-p-4 tw-py-1.5 tw-text-left">
                  {message.error ? (
                    <ErrorMessage>An error occured</ErrorMessage>
                  ) : (
                    message.message && (
                      <Markdown
                        className={cn(
                          "tw-prose tw-max-w-full",
                          !!isMutating &&
                            index === messages.length - 1 &&
                            "loading-dot",
                          "tw-text-xs tw-text-black",
                        )}
                        children={message.message}
                        remarkPlugins={[remarkBreaks]}
                      />
                      /* prose class from tailwindcss-typography allows markdown rendering when using tailwind */
                    )
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {isLoading && messages.length % 2 !== 0 && (
          <LoadingText isLoading={isLoading} />
        )}
        <p className="tw-relative tw--bottom-5 !tw-mt-auto tw-self-center tw-text-center tw-text-xs">
          Bulldog Genie uses AI. Check for mistakes.
        </p>
        <Button
          className={`${
            scrollButtonToggled && sessionId ? "tw-inline-flex" : "tw-hidden"
          } tw-bg-blue hover:tw-bg-blue tw-absolute tw-bottom-36 tw-right-1/2 tw-z-10 -tw-mr-2 tw-size-7 tw-rounded-full tw-outline tw-outline-1 tw-outline-white/30`}
          size={"icon"}
          onClick={() => {
            setScrollButtonToggled(false);
            if (scrollContainer.current) {
              scrollContainer.current.scrollTo({
                top:
                  scrollContainer.current.scrollHeight -
                  scrollContainer.current.clientHeight,
                behavior: "smooth",
              });
            }
          }}
        >
          <ArrowDown className="tw-size-4 tw-text-white" aria-hidden />
        </Button>
      </motion.div>
    );
  },
);

export { EmbeddedMessages };
