import { motion, MotionProps } from "motion/react";
import {
  Dispatch,
  forwardRef,
  HTMLAttributes,
  SetStateAction,
  useRef,
  useState,
} from "react";

import { useAutoScroll } from "@/hooks/useAutoScroll.ts";
import { useChat } from "@/hooks/useChat.ts";
import { debounce } from "@/utils/debounce.ts";
import newChatIcon from "@/assets/embedded/new-chat.svg";
import { Button } from "../ui/button.tsx";
import xIcon from "@/assets/embedded/x-icon.svg";
// import bulldogGenieBrandLogo from "@/assets/brand/genie-brand-logo.svg";
import { EmbeddedMessages } from "./embeddedMessages.tsx";
import { EmbeddedSuggestions } from "./embeddedSuggestions.tsx";
import { ArrowDown } from "../icons/arrowDown.tsx";
import { EmbeddedInputField } from "./embeddedInputField.tsx";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip.tsx";

const embeddedConfig = {
  id: "default-293ruhferenjhkd",
};

type EmbeddedExpandedChatProps = {
  setIsMinimized: Dispatch<SetStateAction<boolean>>;
} & MotionProps &
  HTMLAttributes<HTMLDivElement>;
const EmbeddedExpandedChat = forwardRef(
  (
    { setIsMinimized, ...props }: EmbeddedExpandedChatProps,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const [scrollButtonToggled, setScrollButtonToggled] = useState(false);

    const { messages, streamMessage, clearStorageHistory, isLoading } = useChat(
      embeddedConfig.id,
      "sessionStorage",
    );
    const scrollContainer = useRef<HTMLDivElement>(null);
    useAutoScroll(scrollContainer);

    async function handleSendMessage(text: string) {
      if (!text) return;

      streamMessage({
        userMessage: text,
        sessionId: sessionStorage.id,
      });
    }

    const handleScroll = debounce(() => {
      if (!scrollContainer.current) return;
      // Show button when user scrolls 25 pixels from the bottom of the chat
      if (
        scrollContainer.current.scrollTop <
        scrollContainer.current.scrollHeight -
          scrollContainer.current.clientHeight -
          25
      ) {
        setScrollButtonToggled(true);
      } else {
        setScrollButtonToggled(false);
      }
    }, 50);

    return (
      <div
        ref={ref}
        {...props}
        className="s:tw-bottom-2 s:tw-right-2 s:tw-max-h-[568px] s:tw-w-[320px] s:tw-rounded-lg tw-fixed tw-bottom-0 tw-right-0 tw-z-[100000] tw-h-full tw-w-full tw-overflow-hidden tw-rounded-none tw-bg-white tw-text-red-500 tw-shadow-md"
      >
        <header className="tw-bg-red s:tw-rounded-tl-lg s:tw-rounded-tr-lg tw-flex tw-h-14 tw-flex-row tw-items-center tw-justify-between tw-rounded-none tw-px-5">
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                className="tw-h-fit tw-w-fit tw-bg-transparent tw-p-0 hover:tw-bg-transparent"
                title="New chat"
                onClick={clearStorageHistory}
                disabled={isLoading}
              >
                <img
                  src={newChatIcon}
                  className="tw-h-5 tw-w-5"
                  alt="New chat"
                />
              </Button>
            </TooltipTrigger>
            <TooltipContent className="tw-bg-blue tw-border-gray tw-shadow-input">
              <div>New Chat</div>
            </TooltipContent>
          </Tooltip>

          <div className="tw-flex tw-h-full tw-flex-row tw-items-center tw-gap-2">
            {/* <img
              src={bulldogGenieBrandLogo}
              className="tw-h-full tw-w-8"
              alt="Bulldog Genie Logo"
            /> */}
            <div className="tw-text-white tw-font-semibold tw-text-base">
              Bulldog Genie
            </div>
          </div>

          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                className="tw-h-fit tw-w-fit tw-bg-transparent tw-p-0 hover:tw-bg-transparent"
                onClick={() => {
                  setIsMinimized((prev) => !prev);
                }}
                title="Minimize chat"
              >
                <img src={xIcon} className="tw-h-5 tw-w-5" alt="Close" />
              </Button>
            </TooltipTrigger>
            <TooltipContent className="tw-bg-blue tw-border-gray tw-shadow-input">
              <div>Minimize Chat</div>
            </TooltipContent>
          </Tooltip>
        </header>
        <div className="tw-divider tw-absolute tw-h-4 tw-w-full tw-bg-gradient-to-b tw-from-white tw-to-transparent tw-blur-[1px]"></div>
        <div
          className="tw-target tw-text-blue tw-mb-auto tw-flex tw-w-full tw-max-w-full tw-flex-col tw-items-end tw-space-y-2 tw-p-4 tw-pb-[60px] tw-pt-4 tw-h-[calc(100%-44px)]"
          onScroll={handleScroll}
          ref={scrollContainer}
        >
          {messages.length > 0 ? (
            <EmbeddedMessages messages={messages} isLoading={isLoading} />
          ) : (
            <EmbeddedSuggestions handleSuggestionClick={handleSendMessage} />
          )}
          <Button
            className={`${
              scrollButtonToggled ? "tw-visible" : "tw-hidden"
            } tw-bg-blue hover:tw-bg-blue tw-absolute tw-bottom-40 tw-right-1/2 tw-z-30 tw--mr-2 tw-size-7 tw-rounded-full tw-outline tw-outline-1 tw-outline-white/30`}
            size={"icon"}
            onClick={() => {
              setScrollButtonToggled(false);
              if (scrollContainer.current) {
                scrollContainer.current.scrollTo({
                  top:
                    scrollContainer.current.scrollHeight -
                    scrollContainer.current.clientHeight,
                  behavior: "smooth",
                });
              }
            }}
          >
            <ArrowDown className="tw-size-4 tw-text-white" aria-hidden />
          </Button>
        </div>
        <EmbeddedInputField
          handleSendMessage={handleSendMessage}
          isPending={isLoading}
        />
      </div>
    );
  },
);

const EmbeddedExpandedChatM = motion.create(EmbeddedExpandedChat, {
  forwardMotionProps: true,
});

export { EmbeddedExpandedChatM };
