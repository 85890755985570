import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export const Computer = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="68"
      height="54"
      viewBox="0 0 68 54"
      fill="currentColor"
      {...props}
    >
      <g clipPath="url(#clip0_1089_702)">
        <path
          d="M0.434082 3.04402C0.434082 1.5591 1.63786 0.355347 3.12276 0.355347H59.708C61.1929 0.355347 62.3966 1.55912 62.3966 3.04402V39.2179H0.434082V3.04402Z"
          fill="currentColor"
        />
        <path
          d="M-3.64893 47.312C-3.64893 46.0445 -3.64893 45.4108 -3.25518 45.017C-2.86143 44.6233 -2.2277 44.6233 -0.96024 44.6233H63.7899C65.0574 44.6233 65.6911 44.6233 66.0849 45.017C66.4786 45.4108 66.4786 46.0445 66.4786 47.312V48.0747C66.4786 50.6096 66.4786 51.8771 65.6911 52.6646C64.9036 53.4521 63.6362 53.4521 61.1013 53.4521H1.72844C-0.806474 53.4521 -2.07393 53.4521 -2.86143 52.6646C-3.64893 51.8771 -3.64893 50.6096 -3.64893 48.0747V47.312Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1089_702">
          <rect
            width="71.25"
            height="53.7736"
            fill="white"
            transform="translate(-3.87598 -0.00170898)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
