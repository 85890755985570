import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export const Document = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="69"
      height="74"
      viewBox="0 0 69 74"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.7203 -13.1899V8.481V8.64112C38.7196 9.37518 38.7188 10.3296 38.8308 11.163C38.9693 12.1933 39.3518 13.7057 40.6426 14.9964C41.9331 16.287 43.4455 16.6695 44.4758 16.8081C45.3093 16.92 46.2636 16.9192 46.9977 16.9185H47.1578H68.829V47.1522C68.829 59.3431 68.829 65.4385 65.0417 69.2258C61.2546 73.0131 55.1591 73.0131 42.9683 73.0131H25.7276C13.5368 73.0131 7.44117 73.0131 3.65393 69.2258C-0.133301 65.4385 -0.133301 59.3431 -0.133301 47.1522V12.6709C-0.133301 0.480059 -0.133301 -5.6154 3.65393 -9.40264C7.44117 -13.1899 13.5366 -13.1899 25.7276 -13.1899H38.7203ZM43.3453 -13.1588V8.4935C43.3453 10.5922 45.0467 12.2935 47.1453 12.2935H68.7979C68.7431 10.7266 68.5915 9.64337 68.1728 8.63256C67.5167 7.04856 66.271 5.80281 63.7795 3.31119L52.3279 -8.14023C49.8364 -10.6318 48.5906 -11.8775 47.0065 -12.5337C45.9956 -12.9524 44.9125 -13.1039 43.3453 -13.1588ZM16.7297 34.2218C16.7297 31.633 18.8284 29.5343 21.4172 29.5343H47.2781C49.867 29.5343 51.9656 31.633 51.9656 34.2218C51.9656 36.8106 49.867 38.9093 47.2781 38.9093H21.4172C18.8284 38.9093 16.7297 36.8106 16.7297 34.2218ZM21.4172 46.7749C18.8284 46.7749 16.7297 48.8736 16.7297 51.4624C16.7297 54.0512 18.8284 56.1499 21.4172 56.1499H38.6578C41.2466 56.1499 43.3453 54.0512 43.3453 51.4624C43.3453 48.8736 41.2466 46.7749 38.6578 46.7749H21.4172Z"
        fill="currentColor"
      />
    </svg>
  );
};
