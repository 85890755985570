import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export const Truck = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="64"
      viewBox="0 0 70 64"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_1108_667)">
        <path
          d="M50.198 58.6021C54.5646 58.6021 58.1044 55.0622 58.1044 50.6956C58.1044 46.329 54.5646 42.7892 50.198 42.7892C45.8313 42.7892 42.2915 46.329 42.2915 50.6956C42.2915 55.0622 45.8313 58.6021 50.198 58.6021Z"
          stroke="currentColor"
          strokeWidth="6.62791"
        />
        <path
          d="M22.5266 58.6021C26.8932 58.6021 30.433 55.0622 30.433 50.6956C30.433 46.329 26.8932 42.7892 22.5266 42.7892C18.16 42.7892 14.6201 46.329 14.6201 50.6956C14.6201 55.0622 18.16 58.6021 22.5266 58.6021Z"
          stroke="currentColor"
          strokeWidth="6.62791"
        />
        <path
          d="M6.71298 30.9296H30.4323V3.25702M30.4323 3.25702V11.1634H2.75977V47.3817C2.75977 49.212 4.24347 50.6957 6.07372 50.6957H14.6194M30.4323 3.25702H46.2451L65.3895 18.5724C65.7825 18.8869 66.0114 19.3629 66.0114 19.8663V26.9764M66.0114 26.9764H50.1985V11.1634H54.1517M66.0114 26.9764V47.3817C66.0114 49.212 64.5275 50.6957 62.6974 50.6957H58.1049M42.2919 50.6957H30.4323"
          stroke="currentColor"
          strokeWidth="6.62791"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1108_667">
          <rect
            width="71.25"
            height="64.6221"
            fill="white"
            transform="translate(-1.8999 -1.53107)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
