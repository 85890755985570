import { useRef } from "react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { SendIcon } from "../icons/embedded/sendIcon.tsx";

const inputFieldConfig = {
  minHeight: "30",
  maxHeight: "112",
};

export function EmbeddedInputField({
  handleSendMessage,
  isPending,
}: {
  handleSendMessage: (text: string) => Promise<void>;
  isPending: boolean;
}) {
  const textAreaElement = useRef<HTMLTextAreaElement>(null);

  const textAreaHeightAdjust = () => {
    if (!textAreaElement.current) {
      return;
    }

    if (!textAreaElement.current.textLength) {
      textAreaElement.current.style.height = `${inputFieldConfig.minHeight}px`;
      return;
    }

    textAreaElement.current.style.height = `${Math.max(
      textAreaElement.current.scrollHeight,
      30,
    )}px`;
  };

  function handleClick() {
    if (!textAreaElement.current) return;

    const text = textAreaElement.current.value.trim();
    if (text.length < 1) return;
    handleSendMessage(text);

    // Resize text box
    textAreaElement.current.value = "";
    textAreaElement.current.style.height = `${inputFieldConfig.minHeight}px`;
  }

  return (
    <div
      id="inputfield"
      className="s:tw-rounded-lg tw-absolute tw-bottom-0 tw-z-10 tw-flex tw-w-full tw-justify-center tw-bg-inherit tw-px-5"
    >
      <div className="tw-flex tw-h-auto tw-w-full tw-max-w-full tw-flex-row tw-items-center tw-justify-between tw-gap-3 tw-rounded-2xl tw-bg-white tw-py-3">
        <Textarea
          id="textInput"
          autoFocus
          placeholder="How can I help?"
          className="tw-pb-auto tw-border-gray tw-scroll-bar-hidden no-scrollbar tw-shadow-input tw-ring-blue tw-row-auto tw-h-8 tw-max-h-28 tw-min-h-8 tw-flex-grow tw-resize-none tw-overflow-scroll tw-scroll-smooth tw-rounded-lg tw-border tw-bg-inherit tw-align-middle tw-text-xs tw-text-black tw-outline-none"
          ref={textAreaElement}
          onChange={textAreaHeightAdjust}
          onKeyUp={(e) => {
            if (isPending) return;
            e.key === "Enter" && !e.shiftKey && handleClick();
          }}
        ></Textarea>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              id="submit"
              size="sm"
              onClick={handleClick}
              disabled={isPending}
              className="tw-text-2xs tw-text-blue tw-bg-blue/90 hover:tw-bg-blue tw-ring-blue tw-ml-auto tw-rounded-full tw-ring-offset-1"
            >
              <SendIcon></SendIcon>
            </Button>
          </TooltipTrigger>
          <TooltipContent className="tw-bg-blue tw-border-gray tw-shadow-input">
            <p>Send</p>
          </TooltipContent>
        </Tooltip>
      </div>
    </div>
  );
}
