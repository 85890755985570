import { forwardRef, memo, useEffect, useState } from "react";
import { GoogleMap } from "./googleMap";
import { DirectionsRenderer } from "./directions";
import { Button } from "@/components/ui/button";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import { LocateFixed, SquareArrowOutUpRight } from "lucide-react";
import {
  AdvancedMarker,
  useMapsLibrary,
  ControlPosition,
  MapControl,
} from "@vis.gl/react-google-maps";
import {
  INVALID_COORDS,
  isInvalidCoords,
  useDirectionsService,
} from "@/hooks/useDirectionsService";
import { toast } from "sonner";
import { AnimatePresence, motion } from "motion/react";

const geolocation = navigator.geolocation;

const MotionButton = motion.create(Button);

/** Success callback for getGeolocation */
const success = (position: GeolocationPosition) => {
  const coords: google.maps.LatLngLiteral = {
    lat: position.coords.latitude,
    lng: position.coords.longitude,
  };
  return coords;
};

type MapProps = {
  originAddress?: string;
  destinationAddress: string;
};

const Map = memo(function MapComponent({
  originAddress,
  destinationAddress,
}: MapProps) {
  const [showMap, setShowMap] = useState<boolean>(false);
  const [origin, setOrigin] = useState<
    null | string | google.maps.LatLngLiteral
  >(null);
  const [destinationCoords, setDestinationCoords] =
    useState<null | google.maps.LatLngLiteral>(null);
  const [userLocationPermission, setUserLocationPermission] =
    useState<boolean>(false);
  const directions = useDirectionsService(origin, destinationCoords);
  const placesLib = useMapsLibrary("places");

  // Set destination coordinates when map is shown
  useEffect(() => {
    if (!showMap || !placesLib || destinationCoords) return;
    generateCoordinates(destinationAddress);

    async function generateCoordinates(location: string) {
      if (!placesLib) return;
      try {
        const request = {
          textQuery: location,
          fields: ["location"],
        };
        const { places } = await placesLib.Place.searchByText(request);

        if (places.length === 0 || !places[0].location) {
          throw new Error("Google Maps could not locate that address");
        }

        // Convert result to latlng literal
        const coords: google.maps.LatLngLiteral = {
          lat: places[0].location.lat(),
          lng: places[0].location.lng(),
        };
        setDestinationCoords(coords);
      } catch (err) {
        console.error(err);
        toast.error("Could not load map");
        setDestinationCoords(INVALID_COORDS);
      }
    }
  }, [showMap, placesLib, destinationCoords, destinationAddress]);

  if (originAddress && !origin) {
    setOrigin(originAddress);
  }

  function getGeolocation() {
    // If geolocation object failed to init, location is not supported by browser
    if (!geolocation) {
      setOrigin(INVALID_COORDS);
      return;
    }
    geolocation.getCurrentPosition(
      (position) => {
        const coords = success(position);
        setOrigin(coords);
      },
      (error) => {
        setOrigin(INVALID_COORDS);
        setUserLocationPermission(false);
        toast.error("Location access denied! Try enabling your location.");
        console.warn(error);
      },
      { enableHighAccuracy: true, maximumAge: 0 },
    );
  }

  function handleUserLocation() {
    setUserLocationPermission(true);
    getGeolocation();
  }

  return (
    <AnimatePresence mode="wait">
      {!showMap ? ( // Hide map until user decides to show it
        <Button
          key="ShowMapButton"
          onClick={() => setShowMap(true)}
          className="tw-bg-primary-dark tw-mb-2 tw-mt-3 tw-self-center tw-border-[1px] tw-px-6 tw-shadow-[0_3px_5px] tw-shadow-black tw-transition-all hover:tw-scale-105 tw-border-[rgba(35,51,78,0.5)]"
        >
          Show Map
        </Button>
      ) : !destinationCoords ? ( // Display loading spinner while destination hasn't been geocoded
        <LoadingSpinner
          key="LoadingSpinner"
          className="tw-mt-1 tw-self-center tw-justify-self-center"
        />
      ) : (
        <>
          {
            // If destination failed to geolocate, do not show map
            isInvalidCoords(destinationCoords) ? null : (
              <motion.div
                key="GoogleMap"
                initial={{ height: 0 }}
                animate={{ height: "auto" }}
                exit={{ height: 0 }}
                transition={{ duration: 0.2 }}
                className="tw-overflow-hidden tw-pt-2"
              >
                <GoogleMap mapId={"mapId"} defaultCenter={destinationCoords}>
                  {directions ? (
                    <DirectionsRenderer directions={directions} />
                  ) : (
                    <>
                      <MapControl position={ControlPosition.TOP_LEFT}>
                        <Button
                          className="tw-ml-3 tw-mt-3 tw-rounded-sm tw-bg-[#ffffff] tw-px-2 hover:tw-bg-white disabled:tw-opacity-100 tw-text-[rgb(90,90,90)] tw-shadow-[0px_1px_5px_rgba(0,0,0,.2)] hover:tw-text-[rgb(25,25,25)]"
                          onClick={handleUserLocation}
                          disabled={userLocationPermission}
                          aria-label={"Use my location"}
                          title={"Use my location"}
                        >
                          {userLocationPermission ? (
                            <LoadingSpinner className="tw-size-5 tw-p-0" />
                          ) : (
                            <LocateFixed className="tw-size-5" />
                          )}
                        </Button>
                      </MapControl>
                      <AdvancedMarker position={destinationCoords} />
                    </>
                  )}
                </GoogleMap>
              </motion.div>
            )
          }
          <ExternalMapLinkM
            exit={{ opacity: 0 }}
            transition={{ duration: 0 }}
            link={
              isInvalidCoords(origin)
                ? `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    destinationAddress,
                  )}`
                : `https://www.google.com/maps/dir/?api=1&${
                    originAddress
                      ? "origin=" + encodeURIComponent(originAddress)
                      : ""
                  }&destination=${encodeURIComponent(destinationAddress)}`
            }
          />
          <MotionButton
            onClick={() => setShowMap(false)}
            className="tw-bg-primary-dark tw-mb-2 tw-mt-3 tw-self-center tw-border-[1px] tw-border-[rgba(35,51,78,0.5)] tw-px-6 tw-shadow-[0_3px_5px] tw-shadow-black tw-transition-all hover:tw-scale-105"
          >
            Hide Map
          </MotionButton>
        </>
      )}
    </AnimatePresence>
  );
});

const ExternalMapLink = forwardRef<HTMLAnchorElement, { link: string }>(
  ({ link }, ref) => {
    return (
      <a
        ref={ref}
        href={link}
        target="_blank"
        className="tw-mt-1 tw-flex tw-w-max tw-flex-row tw-items-center tw-underline"
      >
        <p>Open in Google Maps</p>{" "}
        <SquareArrowOutUpRight className="tw-ml-1 tw-size-3" />
      </a>
    );
  },
);

const ExternalMapLinkM = motion.create(ExternalMapLink);

export { Map };
