import { useLocalStorage } from "./useLocalStorage";
import { useSessionStorage } from "./useSessionStorage";

type UseStorageType<T> = {
  key: string;
  sessionId?: string;
  storageType: "localStorage" | "sessionStorage";
  initialValue: T;
};

export function useStorage<T>({
  key,
  storageType,
  initialValue,
}: UseStorageType<T>) {
  const { localValue, setLocalVal, clearLocalHistory } = useLocalStorage<T>({
    key,
    initialValue,
  });
  const { sessionValue, setSessionVal, clearSessionHistory } =
    useSessionStorage<T>({
      key,
      initialValue,
    });

  return storageType === "localStorage"
    ? ([localValue, setLocalVal, clearLocalHistory] as const)
    : ([sessionValue, setSessionVal, clearSessionHistory] as const);
}
