import { useState } from "react";

export const LoadingText = ({ isLoading }: { isLoading: boolean }) => {
  const loadingMessages = [
    "Crunching the numbers...",
    "Gathering the info...",
    "Finding the best answer...",
    "Piecing it together...",
    "Gathering insights...",
    "Making sure everything's right...",
  ];
  const [loadingMessage, setLoadingMessage] = useState("Thinking...");
  if (isLoading) {
    setTimeout(() => {
      setLoadingMessage(
        loadingMessages[Math.floor(Math.random() * loadingMessages.length)],
      );
    }, 5000);
  }

  return (
    <div className="tw-flex tw-w-full tw-place-content-center tw-pt-2">
      <div className="tw-max-w-chat tw-relative tw-flex tw-w-full tw-flex-row tw-self-start">
        <div className="tw-flex tw-min-w-0 tw-flex-row tw-text-wrap tw-break-words tw-rounded-2xl tw-bg-transparent tw-py-1 tw-text-left">
          <svg
            className="tw-mr-3 tw-h-5 tw-w-5 tw-animate-spin tw-text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="tw-opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="tw-opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          {loadingMessage}
        </div>
      </div>
    </div>
  );
};
