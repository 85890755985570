/* eslint-disable @typescript-eslint/no-explicit-any */

function debounce(func: any, timeout = 300) {
  let timer: NodeJS.Timeout;
  return function (this: any, ...args: any[]) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export { debounce };
