import { useQueryClient } from "@tanstack/react-query";
import { Button, ButtonProps } from "./ui/button";
import { useNavigate, useParams } from "react-router-dom";
import { forwardRef } from "react";
import { House } from "lucide-react";
import { cn } from "@/utils/cn";

type HomeButtonProps = ButtonProps & {
  clearHistory: () => void;
};

export const HomeButton = forwardRef<HTMLButtonElement, HomeButtonProps>(
  ({ className, clearHistory, ...props }, ref) => {
    const queryClient = useQueryClient();
    const { sessionId } = useParams();
    const navigate = useNavigate();

    const handleClick = () => {
      queryClient.invalidateQueries({
        queryKey: ["messages", sessionId],
        refetchType: "none",
      });
      clearHistory();
      navigate("/");
    };

    return (
      <Button
        variant="ghost"
        size="icon"
        title="Go to Home" // ShadCN tooltip made motion animations laggy
        onClick={handleClick}
        className={cn("tw-group", className)}
        {...props}
        ref={ref}
      >
        <House
          color="#ffffff"
          className={
            "tw-size-7 tw-text-white tw-opacity-75 tw-transition-opacity group-disabled:tw-opacity-40"
          }
          aria-hidden
        />
      </Button>
    );
  },
);
