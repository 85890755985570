import { Map, MapProps } from "@vis.gl/react-google-maps";
import { ReactNode } from "react";
import { cn } from "@/utils";

type GoogleMapProps = MapProps & {
  children?: ReactNode;
};

// Wrapper to consolidate shared attributes/styling of map instances
const GoogleMap: React.FC<GoogleMapProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <Map
      className={cn(
        "map-border tw-h-full tw-min-h-[20rem] tw-w-[999px] tw-max-w-full sm:tw-min-h-[30rem]",
        className,
      )}
      reuseMaps={true}
      disableDefaultUI={true}
      gestureHandling={"cooperative"}
      fullscreenControl={true}
      defaultZoom={17}
      {...props}
    >
      {children}
    </Map>
  );
};

export { GoogleMap };
