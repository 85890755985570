import { EmbeddedExpandedChatM } from "@/components/embedded/embeddedExpanded.tsx";
import { EmbeddedMinimizeChatM } from "@/components/embedded/embeddedMinimized.tsx";

import { AnimatePresence } from "motion/react";
import { useEffect, useState } from "react";

const motionVariantsExpanded = {
  initial: { opacity: 0, scale: 0.5, x: 50 },
  animate: { opacity: 1, scale: 1, x: 0 },
  exit: { opacity: 0, scale: 0.3, x: 100 },
  transition: { duration: 0.3, ease: "easeInOut" },
};

const motionVariantsMinimized = {
  initial: { opacity: 0, scale: 0.5, y: -50 },
  animate: { opacity: 1, scale: 1, y: 0 },
  exit: { opacity: 0, scale: 3, y: -100 },
  transition: { duration: 0.1, ease: "easeInOut" },
};

function EmbeddedChat() {
  const [isMinimized, setIsMinimized] = useState(true);

  useEffect(() => {
    fetch(import.meta.env.VITE_PROMPT_BASE_URL + "/analytics/visit");
  }, []);

  return (
    <AnimatePresence>
      {isMinimized ? (
        <EmbeddedMinimizeChatM
          onClick={() => {
            setIsMinimized((prev) => !prev);
          }}
          key="minimized"
          initial={motionVariantsMinimized.initial}
          animate={motionVariantsMinimized.animate}
          exit={motionVariantsMinimized.exit}
          transition={motionVariantsMinimized.transition}
        />
      ) : (
        <EmbeddedExpandedChatM
          setIsMinimized={setIsMinimized}
          key="expanded"
          initial={motionVariantsExpanded.initial}
          animate={motionVariantsExpanded.animate}
          exit={motionVariantsExpanded.exit}
          transition={motionVariantsExpanded.transition}
        />
      )}
    </AnimatePresence>
  );
}

export { EmbeddedChat };
