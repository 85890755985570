import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export const Glasses = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="67"
      height="39"
      viewBox="0 0 67 39"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_1089_1626)">
        <path
          d="M-2.21631 17.5541C-2.21631 14.9653 -0.117649 12.8666 2.47119 12.8666H19.9367C22.5256 12.8666 24.6242 14.9653 24.6242 17.5541V31.6646C24.6242 34.2534 22.5256 36.3521 19.9367 36.3521H2.47118C-0.117649 36.3521 -2.21631 34.2534 -2.21631 31.6646V17.5541Z"
          stroke="currentColor"
          strokeWidth="3.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.0439 17.5541C38.0439 14.9653 40.1425 12.8666 42.7314 12.8666H60.197C62.7858 12.8666 64.8845 14.9653 64.8845 17.5541V31.6646C64.8845 34.2534 62.7858 36.3521 60.197 36.3521H42.7314C40.1425 36.3521 38.0439 34.2534 38.0439 31.6646V17.5541Z"
          stroke="currentColor"
          strokeWidth="3.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M-2.21631 16.2216L3.19822 5.39245C3.99225 3.8044 5.61533 2.80127 7.39086 2.80127H11.204M64.885 16.2216L59.4704 5.39245C58.6764 3.8044 57.0533 2.80127 55.2778 2.80127H51.4647"
          stroke="currentColor"
          strokeWidth="3.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.624 16.2216H38.0443"
          stroke="currentColor"
          strokeWidth="3.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1089_1626">
          <rect
            width="71.25"
            height="38.4375"
            fill="white"
            transform="translate(-4.25 0.219971)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
