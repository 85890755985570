import { useSidebar } from "@/hooks/useSidebar";
import { Button } from "../ui/button";
import { X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useSession } from "@/hooks/useSession";
import { cn } from "@/utils/cn";
import { TSession } from "@/contexts/sessionContext";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../ui/alert-dialog";
import { Trash } from "../icons/trash";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import { useState, useRef, forwardRef } from "react";
import { toast } from "sonner";
import { Input } from "../ui/input";
import { Pencil } from "../icons/pencil";
import { NewChat } from "../icons/newChat";

type ChatHistoryProps = {
  className: string;
};

export const ChatHistoryDesktop = forwardRef<HTMLDivElement, ChatHistoryProps>(
  ({ className }, ref) => {
    const navigate = useNavigate();
    const { sessionList } = useSession();
    const { setSidebarOpen } = useSidebar();

    const handleNewChat = () => {
      setSidebarOpen(false);
      navigate("/");
    };

    const groupedSessions: Record<string, TSession[]> = sessionList.reduce(
      (acc, session) => {
        if (!acc[session.sessionDate]) {
          acc[session.sessionDate] = [];
        }
        acc[session.sessionDate].push(session);
        return acc;
      },
      {} as Record<string, TSession[]>,
    );

    const sessionsByDate: TSession[][] = Object.values(groupedSessions);

    return (
      <div className={className} ref={ref}>
        <div className="tw-max-h-chat-history tw-bg-history-glass tw-border-muted tw-shadow-chat-history tw-relative tw-bottom-10 tw-z-20 tw-flex tw-h-96 tw-flex-col tw-overflow-hidden tw-rounded-3xl tw-border">
          <div className="tw-flex tw-flex-grow tw-flex-col tw-overflow-y-auto tw-bg-transparent tw-px-2">
            <h2 className="tw-mb-2 tw-mt-6 tw-self-start tw-text-nowrap tw-pl-2 tw-text-3xl tw-font-light">
              Our History
            </h2>
            <div className="tw-h-full tw-max-h-full tw-list-none tw-space-y-5 tw-pr-1 tw-pt-4">
              {sessionsByDate
                .slice()
                .reverse()
                .map((sessionGroup) => (
                  <SessionGroup
                    sessionGroup={sessionGroup.slice().reverse()}
                    key={sessionGroup[0].sessionDate}
                  />
                ))}
            </div>
          </div>
          <div className="tw-border-muted tw-bg-primary-dark tw-align-center tw-flex tw-flex-row tw-justify-center tw-space-x-1 tw-border-t tw-py-3">
            <Button
              size="icon"
              variant="ghost"
              title="Close History"
              onClick={() => setSidebarOpen(false)}
            >
              <X className="tw-h-full tw-w-full" />
            </Button>
            <Button
              size="icon"
              variant="ghost"
              title="New Conversation"
              onClick={handleNewChat}
            >
              <NewChat className="tw-text-white" />
            </Button>
          </div>
        </div>
      </div>
    );
  },
);

const SessionGroup = ({ sessionGroup }: { sessionGroup: TSession[] }) => {
  return (
    <div className="tw-flex tw-flex-col tw-space-y-1">
      <p className="tw-px-4 tw-text-left tw-text-[#9D9D9D]">
        {sessionGroup[0].sessionDate}
      </p>
      {sessionGroup.map((session) => (
        <HistoryItem
          session={session}
          key={session.sessionId}
          className="tw-relative tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-end tw-rounded-lg tw-px-5"
        />
      ))}
    </div>
  );
};

interface HistoryItem {
  session: TSession;
  className?: string;
}

const HistoryItem = ({ session, className }: HistoryItem) => {
  const isMutating = useIsMutating();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { sessionId, editName, remove } = useSession();
  const [isEditingName, setIsEditingName] = useState(false);
  const [editedName, setEditedName] = useState<string>(session.sessionName);
  const inputRef = useRef<HTMLInputElement>(null);
  const { setMobileSidebarOpen, setSidebarOpen } = useSidebar();

  function handleNameChange() {
    if (!inputRef.current) return;
    setEditedName(inputRef.current.value);
  }

  function handleNameSubmit() {
    editName(session.sessionId, editedName);
    setIsEditingName(false);
  }

  function handleClickSession() {
    if (sessionId === session.sessionId) return;
    queryClient.invalidateQueries({
      queryKey: ["messages", sessionId],
      refetchType: "none",
    });
    setMobileSidebarOpen(false);
    setSidebarOpen(false);
    navigate(`/s/${session.sessionId}`);
  }

  return (
    <div
      className={cn(
        "tw-transition-all tw-duration-300 hover:tw-bg-white/25 data-[active=true]:tw-bg-white/25",
        className,
      )}
      data-active={session.sessionId === sessionId}
    >
      {isEditingName ? (
        <div className="tw-mr-auto tw-flex tw-h-full tw-w-full tw-min-w-0 tw-flex-col tw-space-y-1 tw-p-0 tw-text-left hover:tw-bg-transparent">
          <Input
            type="text"
            value={editedName}
            onChange={handleNameChange}
            onBlur={handleNameSubmit}
            onKeyDown={(e) => e.key === "Enter" && handleNameSubmit()}
            className="tw-relative tw-right-1 tw-my-0 tw-h-fit tw-w-full tw-px-1 tw-py-2 tw-text-base tw-font-medium"
            ref={inputRef}
            autoFocus
          />
        </div>
      ) : (
        <Button
          variant={"ghost"}
          key={session.sessionId}
          disabled={!!isMutating}
          onClick={handleClickSession}
          className="tw-mr-auto tw-flex tw-h-full tw-w-full tw-min-w-0 tw-flex-col tw-p-0 tw-py-2.5 tw-text-left hover:tw-bg-transparent"
        >
          <span className="tw-w-full tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-text-base">
            {session.sessionName}
          </span>
        </Button>
      )}
      <Button
        variant="ghost"
        size="icon"
        title="Edit Name"
        className="tw-h-min tw-rounded-lg tw-py-1.5 tw-text-xs"
        onClick={() => setIsEditingName(true)}
      >
        <Pencil />
      </Button>
      <AlertDialog>
        <AlertDialogTrigger asChild={true}>
          <Button
            variant={"ghost"}
            size="icon"
            title="Delete Conversation"
            className="tw-text-red tw-flex tw-h-min tw-flex-row tw-py-1.5 tw-text-xs"
          >
            <Trash />
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent className="tw-bg-primary-dark tw-overflow-hidden tw-rounded-md tw-border-white tw-p-0 tw-pb-2 tw-text-white sm:tw-max-w-lg tw-max-w-[80%]">
          <AlertDialogHeader>
            <AlertDialogTitle className="tw-bg-inherit tw-px-6 tw-py-2 tw-text-white">
              <div>Are you sure? </div>
            </AlertDialogTitle>
            <AlertDialogDescription className="tw-px-6 tw-pt-6 tw-text-left">
              This action cannot be undone. This will permanently delete this
              conversation.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter className="tw-px-6">
            <AlertDialogCancel className="tw-rounded-xl tw-border-none">
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                remove(session.sessionId);
                toast.success("Conversation has been deleted");
              }}
              className="tw-text-red hover:tw-bg-red tw-rounded-xl tw-bg-transparent tw-font-semibold hover:tw-text-white"
            >
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
          º
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};
