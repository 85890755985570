import * as React from "react";

import { cn } from "@/utils";

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    return (
      <textarea
        className={cn(
          "tw-border-input tw-bg-background tw-ring-offset-background placeholder:tw-text-muted-foreground focus-visible:tw-ring-ring tw-flex tw-min-h-[80px] tw-w-full tw-rounded-md tw-border tw-px-3 tw-py-2 tw-text-sm focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-offset-0 disabled:tw-cursor-not-allowed disabled:tw-opacity-50",
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Textarea.displayName = "Textarea";

export { Textarea };
