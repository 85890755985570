import { memo, useEffect, useRef, useState } from "react";
import Markdown from "@/components/ui/markdown";
import remarkBreaks from "remark-breaks";
import { TChatMessage } from "@/types/message";
import { ErrorMessage } from "@/components/ui/errorMessage";
import { Map } from "@/components/map/map";
import { LoadingText } from "./loadingText";
import { useIsMutating } from "@tanstack/react-query";
import { cn, debounce } from "@/utils";
import { useAutoScroll } from "@/hooks/useAutoScroll";
import { Button } from "./ui/button";
import { useLocation, useParams } from "react-router-dom";
import { ArrowDown } from "lucide-react";
import { motion } from "motion/react";

const newChatInitialMessage =
  "Hi, I'm Bulldog Genie, your friendly AI assistant ready to answer your Fresno State questions—anytime, anywhere!";

const Messages = memo(
  ({
    messages,
    isLoading,
  }: {
    messages: TChatMessage[];
    isLoading: boolean;
  }) => {
    const { sessionId } = useParams();
    const isMutating = useIsMutating({ mutationKey: ["messages"] });
    const location = useLocation();
    const scrollContainer = useRef<HTMLDivElement>(null);
    const [scrollButtonToggled, setScrollButtonToggled] = useState(false);
    useAutoScroll(scrollContainer);

    // Hacky way of hiding scroll button when navigating to different conversation
    useEffect(() => {
      setScrollButtonToggled(false);
    }, [sessionId]);

    const handleScroll = debounce(() => {
      if (!scrollContainer.current) return;
      // Show button when user scrolls 25 pixels from the bottom of the chat
      if (
        scrollContainer.current.scrollTop <
        scrollContainer.current.scrollHeight -
          scrollContainer.current.clientHeight -
          25
      ) {
        setScrollButtonToggled(true);
      } else {
        setScrollButtonToggled(false);
      }
    }, 50);

    return (
      <motion.div
        className="tw-mb-auto tw-flex tw-h-full tw-min-h-dvh tw-w-full tw-max-w-full tw-flex-col tw-items-end tw-space-y-8 tw-overflow-y-auto tw-overflow-x-hidden tw-px-5 tw-pb-40"
        key={location.pathname} // force unmount & remount for animation
        onScroll={handleScroll}
        ref={scrollContainer}
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ scale: 0.2, opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <div className="tw-sticky tw-top-0 tw-z-40 tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-center tw-py-5">
          <div className="tw-bg-blur tw-absolute tw--left-5 tw-top-0 tw-z-10 tw-h-full tw-w-dvw" />
          <h3 className="tw-relative tw-z-20 tw-text-xl tw-font-bold tw-tracking-[1.1px]">
            Bulldog Genie
          </h3>
        </div>
        {messages.map((message, index) => {
          // User Message
          return message.owner === "user" ? (
            <div
              className="tw-flex tw-w-full tw-place-content-center"
              key={message.id}
            >
              <div className="tw-max-w-chat tw-flex tw-w-full tw-justify-end">
                <div className="tw-bg-blue tw-rounded-message tw-flex tw-flex-col tw-self-end tw-whitespace-pre-wrap tw-text-wrap tw-break-words tw-border-[1px] tw-px-5 tw-py-2.5 tw-text-left tw-text-base tw-max-w-[90%] tw-border-[rgba(43,62,94,0.50)]">
                  <p>{message.message}</p>
                </div>
              </div>
            </div>
          ) : (
            // Bot Message
            <div
              className="tw-flex tw-w-full tw-place-content-center"
              key={message.id}
            >
              <div className="tw-max-w-chat tw-relative tw-flex tw-w-full tw-flex-row tw-self-start">
                <div className="tw-flex tw-min-w-0 tw-flex-col tw-text-wrap tw-break-words tw-rounded-2xl tw-bg-transparent tw-py-1 tw-text-left tw-w-full">
                  {message.error ? (
                    <ErrorMessage>An error occured</ErrorMessage>
                  ) : (
                    message.message && (
                      <Markdown
                        className={cn(
                          "tw-prose tw-prose-invert tw-max-w-full",
                          !!isMutating &&
                            index === messages.length - 1 &&
                            "loading-dot",
                        )}
                        children={message.message}
                        remarkPlugins={[remarkBreaks]}
                      />
                      /* prose class from tailwindcss-typography allows markdown rendering when using tailwind */
                    )
                  )}
                  {message.directions?.destination &&
                    Object.keys(message.directions.destination).length > 0 && (
                      <Map
                        destinationAddress={message.directions?.destination}
                        originAddress={message.directions?.origin}
                      />
                    )}
                </div>
              </div>
            </div>
          );
        })}
        {messages.length === 0 ? (
          <div className="tw-flex tw-w-full tw-place-content-center">
            <div className="tw-max-w-chat tw-relative tw-flex tw-w-full tw-flex-row tw-self-start">
              <div className="tw-flex tw-min-w-0 tw-flex-col tw-text-wrap tw-break-words tw-rounded-2xl tw-bg-transparent tw-py-1 tw-text-left">
                <Markdown
                  className={cn("tw-prose tw-prose-invert tw-max-w-full")}
                  children={newChatInitialMessage}
                  remarkPlugins={[remarkBreaks]}
                />
              </div>
            </div>
          </div>
        ) : null}
        {isLoading && messages.length % 2 !== 0 && (
          <LoadingText isLoading={isLoading} />
        )}
        <p className="tw-relative tw--bottom-10 !tw-mt-auto tw-self-center tw-text-center tw-text-xs">
          Bulldog Genie uses AI. Check for mistakes.
        </p>
        <Button
          className={`${
            scrollButtonToggled && sessionId ? "tw-inline-flex" : "tw-hidden"
          } tw-bg-blue hover:tw-bg-blue tw-absolute tw-bottom-36 tw-right-1/2 tw-z-10 -tw-mr-2 tw-size-7 tw-rounded-full tw-outline tw-outline-1 tw-outline-white/30`}
          size={"icon"}
          onClick={() => {
            setScrollButtonToggled(false);
            if (scrollContainer.current) {
              scrollContainer.current.scrollTo({
                top:
                  scrollContainer.current.scrollHeight -
                  scrollContainer.current.clientHeight,
                behavior: "smooth",
              });
            }
          }}
          title="Scroll to bottom"
        >
          <ArrowDown className="tw-size-4 tw-text-white" aria-hidden />
        </Button>
      </motion.div>
    );
  },
);

export { Messages };
