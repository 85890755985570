import { Button } from "@/components/ui/button";
import { cn } from "@/utils/cn";

const suggestions = [
  "How do I connect to the WiFi?",
  "Tell me about the Bulldog Bound program.",
  "What are the places on campus to eat.",
];

const initialGenieMessage = [
  "Hi I'm Bulldog Genie, Your friendly AI assistant ready to answer your Fresno State questions. How may I help you today?",
  "Choose an option below or type a short message, and I'll assist you right away.",
];

export function EmbeddedSuggestions({
  handleSuggestionClick,
}: {
  handleSuggestionClick: (text: string) => Promise<void>;
}) {
  return (
    <div className="tw-mx-[1px] tw-mb-auto tw-flex tw-h-full tw-w-full tw-max-w-full tw-flex-col tw-items-end tw-space-y-2 tw-overflow-y-auto tw-overflow-x-visible tw-pb-8">
      <div className="tw-flex tw-w-full tw-flex-row tw-flex-wrap"></div>
      <InitialGenieMessage />
      {suggestions.map((s, i) => (
        <SuggestionPrompt
          className="focus-within:tw-bg-blue focus-visible:tw-ring-blue tw-group tw-flex tw-flex-row tw-justify-start tw-ring-offset-2 focus-visible:tw-ring-2 tw-py-1.5"
          key={i + s}
          prompt={s}
          handleSuggestiOnClick={handleSuggestionClick}
        >
          <div className="tw-line-clamp-2 tw-h-fit tw-w-fit tw-text-start tw-text-xs tw-text-white group-focus-within:tw-text-white group-hover:tw-text-white">
            {s}
          </div>
        </SuggestionPrompt>
      ))}
      <div className="tw-relative !tw-mt-auto tw-self-center tw-text-center tw-text-xs tw-text-black">
        Bulldog Genie uses AI. Check for mistakes.
      </div>
    </div>
  );
}

type SuggestionPromptProps = {
  handleSuggestiOnClick: (message: string) => void;
  prompt: string;
} & React.HTMLAttributes<HTMLButtonElement>;

export function SuggestionPrompt({
  className,
  children,
  prompt,
  handleSuggestiOnClick,
  ...props
}: SuggestionPromptProps) {
  return (
    <Button
      className={cn(
        "tw-flex-justify-end tw-border-gray tw-shadow-input tw-bg-blue tw-relative tw-mr-1 tw-flex tw-h-fit tw-w-[200px] tw-overflow-hidden tw-text-wrap tw-rounded-lg tw-border tw-px-3 tw-py-1 tw-text-white",
        className,
      )}
      onClick={() => handleSuggestiOnClick(prompt)}
      {...props}
    >
      {children}
    </Button>
  );
}

function InitialGenieMessage() {
  return (
    <div className="tw-flex tw-w-full tw-place-content-center">
      <div className="tw-max-w-chat tw-flex tw-w-full tw-flex-col tw-space-y-2 tw-self-start tw-p-1">
        {initialGenieMessage.map((m, i) => (
          <div
            className="tw-shadow-input tw-border-gray tw-line-clamp-2 tw-flex tw-h-fit tw-w-fit tw-min-w-0 tw-flex-col tw-text-wrap tw-break-words tw-rounded-br-lg tw-rounded-tl-lg tw-rounded-tr-lg tw-border tw-p-4 tw-py-1.5 tw-text-start tw-text-xs tw-text-black"
            key={i + m}
          >
            {m}
          </div>
        ))}
      </div>
    </div>
  );
}
