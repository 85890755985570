import { motion, MotionProps } from "motion/react";
import { ButtonHTMLAttributes, forwardRef } from "react";
import { Button } from "../ui/button.tsx";
import chatIcon from "@/assets/embedded/chat-icon.svg";

type EmbeddedMinimizeChatProps = ButtonHTMLAttributes<HTMLButtonElement> &
  MotionProps;
const EmbeddedMinimizeChat = forwardRef(
  (props: EmbeddedMinimizeChatProps, ref: React.Ref<HTMLButtonElement>) => {
    return (
      <Button
        ref={ref}
        {...props}
        className="hover:tw-bg-red-dark tw-bg-red tw-fixed tw-bottom-28 tw-right-7 tw-z-[100000] tw-h-fit tw-w-fit tw-rounded-full tw-p-4 tw-shadow-md tw-transition tw-duration-200 hover:tw-cursor-pointer hover:tw-ease-in"
      >
        <img src={chatIcon} className="tw-h-9 tw-w-9" />
      </Button>
    );
  },
);

const EmbeddedMinimizeChatM = motion.create(EmbeddedMinimizeChat, {
  forwardMotionProps: true,
});

export { EmbeddedMinimizeChatM };
