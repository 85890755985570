import { forwardRef, useRef, useState } from "react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { ArrowUp } from "lucide-react";
import { MessageCirclePlus } from "lucide-react";

//!DISABLE HISTORY
// import { ChatHistoryMobile } from "./chatHistory/chatHistoryMobile";
// import { ChatHistoryDesktopToggle } from "./chatHistory/chatHistoryDesktopToggle";
import { motion } from "motion/react";
import { motionConfig } from "@/configs/motion";

import { HomeButton } from "./homeButton";

const MotionButton = motion.create(Button, { forwardMotionProps: true });
const MHomeButton = motion.create(HomeButton, { forwardMotionProps: true });
//! DISABLE HISTORY
// const MChatHistoryToggle = motion.create(ChatHistoryDesktopToggle, {
//   forwardMotionProps: true,
// });

type InputFieldProps = {
  handleSendMessage: (text: string) => Promise<void>;
  isPending: boolean;
  showHomeButton: boolean;
  handleNewChat: () => void;
  isMessagesEmpty: boolean;
  clearHistory: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

export const InputField = forwardRef<HTMLDivElement, InputFieldProps>(
  (
    {
      handleSendMessage,
      isPending,
      showHomeButton,
      handleNewChat,
      isMessagesEmpty,
      clearHistory,
    },
    ref,
  ) => {
    const textAreaElement = useRef<HTMLTextAreaElement>(null);
    const [text, setText] = useState("");

    const textAreaHeightAdjust = () => {
      if (!textAreaElement.current) {
        return;
      }

      textAreaElement.current.style.height = "auto";
      textAreaElement.current.style.setProperty(
        "height",
        `${textAreaElement.current.scrollHeight}px`,
        "important",
      );
    };

    function handleType(val: string) {
      setText(val);
      textAreaHeightAdjust();
    }

    function handleClick() {
      const msg = text.trim();
      if (msg.length < 1) return;
      handleSendMessage(msg);
      setText("");

      // Resize text box
      if (!textAreaElement.current) return;
      textAreaElement.current.value = "";
      textAreaHeightAdjust();
    }

    return (
      <div
        id="inputfield"
        className="tw-gap-container tw-max-w-chat tw-shadow-input-field tw-relative tw-z-30 tw-mb-4 tw-flex tw-w-full tw-flex-row tw-items-end tw-gap-2 tw-rounded-[18px] tw-bg-[#071A3D] tw-px-3 tw-py-2 md:tw-mb-6"
        ref={ref}
      >
        {showHomeButton && !text && (
          <MHomeButton
            key="Home-Button"
            className="tw-relative tw-mb-1"
            disabled={!!isPending}
            initial={motionConfig.slideRightInitial}
            animate={motionConfig.animate}
            transition={motionConfig.transition}
            clearHistory={clearHistory}
          />
        )}
        {/* //!DISABLE HISTORY */}
        {/* <MChatHistoryToggle
          className="tw-relative tw-mb-1 tw-hidden tw-pb-0.5 md:tw-inline-flex"
          layout
          transition={motionConfig.transition}
        /> */}

        <MotionButton
          size="icon"
          variant="ghost"
          className="tw-group tw-relative tw-mb-1 tw-pb-0.5 md:tw-inline-flex"
          title="New chat"
          onClick={handleNewChat}
          disabled={
            (isMessagesEmpty && location.pathname !== "/") || !!isPending
          }
          layout="position"
          transition={motionConfig.transition}
        >
          <MessageCirclePlus
            color="#ffffff"
            className="tw-h-7 tw-w-7 tw-opacity-80 tw-transition-opacity group-hover:tw-opacity-100"
            aria-hidden
          />
        </MotionButton>

        {/* //!DISABLE HISTORY */}
        {/* <ChatHistoryMobile className="tw-relative tw-mb-1 tw-pb-0.5" /> */}
        <motion.div
          layout
          transition={motionConfig.transition}
          className="tw-relative tw-flex tw-min-h-0 tw-grow tw-flex-row tw-overflow-hidden tw-rounded-[14px] tw-bg-[#00040C] tw-p-1 tw-py-1 tw-pr-2 focus-within:tw-ring-1 focus-within:tw-ring-white focus-within:tw-ring-offset-0"
        >
          <div className="tw-max-h-52 tw-w-full tw-overflow-y-auto">
            <Textarea
              id="textInput"
              autoFocus
              placeholder="Ask me anything!"
              rows={1}
              className="tw-overflow-y-none tw-block tw-min-h-0 tw-w-full tw-resize-none tw-scroll-smooth tw-border-none tw-bg-transparent tw-text-base tw-outline-none focus-visible:tw-ring-0 focus-visible:tw-ring-offset-0"
              ref={textAreaElement}
              value={text}
              onChange={(e) => handleType(e.target.value)}
              onKeyUp={(e) => {
                if (isPending) return;
                e.key === "Enter" && !e.shiftKey && handleClick();
              }}
            />
          </div>
          {text && (
            <MotionButton
              id="submit"
              size="icon"
              onClick={handleClick}
              disabled={isPending}
              className={
                "tw-text-2xs hover:tw-bg-gray tw-relative tw-mb-1.5 tw-ml-1.5 tw-size-7 tw-min-w-7 tw-self-end tw-rounded-full tw-bg-white focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-blue-400"
              }
              title="Send Message"
              initial={motionConfig.slideLeftInitial}
              animate={motionConfig.animate}
              transition={motionConfig.transition}
            >
              <ArrowUp className="tw-size-4 tw-text-black" aria-hidden />
            </MotionButton>
          )}
        </motion.div>
      </div>
    );
  },
);
