import { useState } from "react";

type UseSessionStorageType<T> = {
  key: string;
  initialValue: T;
};

function useSessionStorage<T>({ key, initialValue }: UseSessionStorageType<T>) {
  const getValue = () => {
    const storedValue = sessionStorage.getItem(key);
    try {
      return storedValue ? JSON.parse(storedValue!) : initialValue;
    } catch (error) {
      console.error("Error session storage value: ", error);
      return initialValue;
    }
  };

  const [sessionValue, setSessionValue] = useState<T>(getValue);

  const setSessionVal = (value: T | ((prevValue: T) => T)) => {
    try {
      setSessionValue((prev) => {
        const newValue =
          typeof value === "function" ? (value as (prev: T) => T)(prev) : value; // Support functional updates

        // Update localStorage with the new value
        sessionStorage.setItem(key, JSON.stringify(newValue));

        return newValue; // Return the updated state
      });
    } catch (error) {
      console.error("Error setting session storage value:", error);
    }
  };

  const removeValue = () => {
    try {
      sessionStorage.removeItem(key);
      setSessionValue(initialValue);
    } catch (error) {
      console.error("Error removing session storage value:", error);
    }
  };

  const clearSessionHistory = () => {
    sessionStorage.clear();
  };

  return {
    sessionValue,
    setSessionVal,
    removeValue,
    key,
    clearSessionHistory,
  };
}

export { useSessionStorage };
