import { postRaffle } from "@/services/raffle";
import { TChatSession, TChatMessage, TResponseData } from "@/types/message";
import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";
import { parseStreamedResponse } from "@/lib/response";
import { streamToAsyncIterable } from "@/lib/streamToAsyncIterable";
import { useStorage } from "./useStorage";

/** Hook for managing chat history state */
export const useChat = (
  key: string,
  storageType: "localStorage" | "sessionStorage",
) => {
  const [messages, setMessages, clearHistory] = useStorage<TChatSession>({
    key,
    initialValue: [],
    storageType,
  });

  const { mutate: streamMessage, isPending } = useMutation({
    mutationKey: ["messages"],
    mutationFn: streamResponse,
    retry: false,

    onMutate: async (params) => {
      const previousMessages = messages;

      const userMessage: TChatMessage = {
        id: crypto.randomUUID(),
        owner: "user",
        message: params.userMessage,
      };

      // Optimistically update user message
      addMessage(userMessage);

      return { previousMessages };
    },

    onSuccess: (data) => {
      updateLastMessage(data.message, data.directions);

      //! RAFFLE FEAT
      if (data.email && data.name) {
        postRaffle(data.name, data.email);
      }
    },

    onError: (_err, _params, context) => {
      // Reset chatlog state to previous messages on error
      if (context?.previousMessages) {
        setMessages(context?.previousMessages);
      } else {
        setMessages([]); // Clear history
      }

      toast.error("Server Error: Please try again");
    },
  });

  /** Streamed version of getting a response */
  async function streamResponse({
    // @ts-expect-error passed for use in the onMutate function
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    userMessage,
    sessionId,
  }: {
    userMessage: string;
    sessionId: string;
  }): Promise<TResponseData> {
    try {
      const url = import.meta.env.VITE_PROMPT_BASE_URL + `/chats/openai`;
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          sessionId: sessionId,
          messages: messages,
          responseType: "stream",
        }),
      });

      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
      }

      const stream = response.body?.pipeThrough(new TextDecoderStream());

      // Initialize empty response message which will be updated by stream
      const responseMessage: TChatMessage = {
        id: crypto.randomUUID(),
        owner: "system",
        message: "",
      };
      addMessage(responseMessage);

      let completion = "";

      if (stream) {
        for await (const chunk of streamToAsyncIterable(stream)) {
          completion = completion + chunk;
          // Update the last message in the array with the current stream data
          updateLastMessage(parseStreamedResponse(completion));
        }
      }
      return JSON.parse(completion); // Should be complete valid JSON at this point
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  /** Function to update most recent chat message. Used when streaming response */
  function updateLastMessage(
    message?: string,
    directions?: { origin: string; destination: string },
  ) {
    setMessages((messages) => {
      const messagesCopy = [...messages];
      messagesCopy[messagesCopy.length - 1] = {
        ...messagesCopy[messages.length - 1],
        message: message,
        directions: directions,
      };
      return messagesCopy;
    });
  }

  function addMessage(message: TChatMessage) {
    setMessages((messages) => {
      return [...messages, message];
    });
  }

  function clearStorageHistory() {
    setMessages(() => []);
    clearHistory();
  }

  return {
    /** Array of chat messages */
    messages,

    clearStorageHistory,
    /** Send a message to the API, triggering a streamed response */
    streamMessage,
    /** Check if a request is currently in progress */
    isLoading: isPending,
  };
};
