import { cn } from "@/utils/cn";

type EllipseProps = React.HTMLAttributes<HTMLDivElement>;

const Ellipse = ({ className }: EllipseProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5"
      height="6"
      viewBox="0 0 5 6"
      fill="currentColor"
      className={className}
      aria-hidden
    >
      <circle
        cx="2.5"
        cy="2.5"
        r="2.5"
        transform="matrix(-1 0 0 1 5 0.655762)"
        fill="currentColor"
      />
    </svg>
  );
};

type EllipsisPaginationProps = React.HTMLAttributes<HTMLDivElement> & {
  index: number;
  size: number;
};

export const EllipsisPagination = ({
  index,
  size,
  className,
}: EllipsisPaginationProps) => {
  return (
    <div
      className={cn(
        "tw-flex tw-h-fit tw-items-center tw-justify-center w-full",
        className,
      )}
    >
      <div className="tw-my-1 tw-flex tw-flex-row tw-gap-2 tw-self-center tw-justify-self-center">
        {Array.from({ length: size }).map((_, i) =>
          index === i + 1 ? (
            <Ellipse key={i} className="tw-text-black" />
          ) : (
            <Ellipse key={i} className="tw-text-white" />
          ),
        )}
      </div>
    </div>
  );
};
