import { useLocation, useNavigate, useParams } from "react-router-dom";
import { InputField } from "../components/inputField";
import { Messages } from "../components/messages";
import { Suggestions } from "../components/suggestions";
import { useChat } from "@/hooks/useChat";
import { Toaster } from "@/components/ui/sonner-toast";
import { cn } from "@/utils/cn";
//! REMOVE HISTORY
// import { useSession } from "@/hooks/useSession";
import { useSidebar } from "@/hooks/useSidebar";
import { ChatHistoryDesktop } from "@/components/chatHistory/chatHistoryDesktop";
import { AnimatePresence, motion } from "motion/react";
import { motionConfig } from "@/configs/motion";
import { queryClient } from "@/configs/queryClient.config.ts";
import { useEffect } from "react";

const MSuggestions = motion.create(Suggestions, { forwardMotionProps: true });
const MInputField = motion.create(InputField, { forwardMotionProps: true });
const MChatHistoryDesktop = motion.create(ChatHistoryDesktop, {
  forwardMotionProps: true,
});

const Chat = () => {
  const navigate = useNavigate();
  const { sessionId } = useParams();
  const { isSidebarOpen } = useSidebar();
  //! REMOVE HISTORY
  // const { updateSessionList } = useSession();
  const { messages, clearStorageHistory, streamMessage, isLoading } = useChat(
    sessionId as string,
    "sessionStorage",
  );
  const currentURLPath = useLocation().pathname;

  useEffect(() => {
    fetch(import.meta.env.VITE_PROMPT_BASE_URL + "/analytics/visit");
  }, []);

  const handleNewChat = () => {
    queryClient.invalidateQueries({
      queryKey: ["messages", sessionId],
      refetchType: "none",
    });
    clearStorageHistory();
    navigate(`/s/${crypto.randomUUID()}`);
  };

  // Creates and returns a new sessionId if it does not exist (if user is on the home page)
  const createSessionId = (sessionId: string | undefined) => {
    if (!sessionId) {
      // Generate a new sessionId if it doesn't exist and add it to the session list
      const newSessionId = crypto.randomUUID();
      //! REMOVE HISTORY
      // updateSessionList(newSessionId);
      navigate(`/s/${newSessionId}`);
      return newSessionId;
    } else {
      return sessionId;
    }
  };

  // Send message to API endpoint and update history with response
  async function handleSendMessage(text: string) {
    if (!text) return;
    const _sessionId = createSessionId(sessionId);
    streamMessage({
      userMessage: text,
      sessionId: _sessionId,
    });
  }

  return (
    <>
      <div
        className={cn(
          checkCurrentURLPath(currentURLPath)
            ? "tw-to-blue tw-bg-gradient-to-b tw-from-[#080b16]"
            : "tw-from-blue tw-to-blue md:tw-bg-desktop tw-bg-gradient-to-b",
          "tw-flex tw-flex-col tw-overflow-hidden md:tw-flex-row tw-gap-container",
        )}
      >
        {!checkCurrentURLPath(currentURLPath) && (
          <>
            {/* blurred dots */}
            <div className="tw-bg-blur-white animate-white-blur tw-fixed tw--right-24 tw-top-20 tw-size-40 tw-rounded-full tw-opacity-60 tw-blur-[50px] md:tw--right-[600px] md:tw-top-0 md:tw-size-[800px] md:tw-animate-none md:tw-opacity-40 md:tw-blur-[80px]" />
            <div className="tw-bg-blur-red animate-red-blur tw-fixed tw--left-16 tw-size-40 tw-rounded-full tw-opacity-80 tw-blur-[40px] md:tw--left-[600px] md:tw-top-0 md:tw-size-[800px] md:tw-animate-none md:tw-opacity-60 md:tw-blur-[60px] tw-top-[calc(50%-9rem)]" />
          </>
        )}
        <main className="tw-relative tw-flex tw-h-dvh tw-w-full tw-flex-col tw-items-center tw-text-white">
          {checkCurrentURLPath(currentURLPath) ? (
            <Messages
              key="messages"
              messages={messages}
              isLoading={isLoading}
            />
          ) : (
            <MSuggestions
              key="suggestions"
              handleSuggestionClick={handleSendMessage}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.2 }}
            />
          )}
          <div className="tw-absolute tw-bottom-0 tw-z-10 tw-flex tw-w-full tw-justify-center tw-bg-transparent tw-bg-none tw-px-3">
            {/* bottom fade */}
            <div className="tw-h-bottom-fade tw-bg-bottom-fade tw-pointer-events-none tw-absolute tw-bottom-0 tw-left-0 tw-z-10 tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-center" />
            <AnimatePresence mode="sync">
              {isSidebarOpen ? (
                <MChatHistoryDesktop
                  className="tw-absolute tw-bottom-0 tw-z-50 tw-min-h-16 tw-w-full tw-max-w-md tw-bg-transparent tw-px-4"
                  key="ChatHistoryDesktop"
                  initial={motionConfig.shrinkInitial}
                  animate={motionConfig.shrinkAnimate}
                  exit={motionConfig.shrinkExit}
                  style={{
                    originY: "80%", // animate from bottom
                  }}
                />
              ) : (
                <MInputField
                  handleSendMessage={handleSendMessage}
                  isPending={isLoading}
                  showHomeButton={checkCurrentURLPath(currentURLPath)}
                  key="InputField"
                  handleNewChat={handleNewChat}
                  clearHistory={clearStorageHistory}
                  isMessagesEmpty={messages.length === 0}
                  initial={motionConfig.shrinkInitial}
                  animate={motionConfig.shrinkAnimate}
                  exit={motionConfig.shrinkExit}
                />
              )}
            </AnimatePresence>
          </div>
        </main>
      </div>
      <Toaster
        className="tw-pointer-events-auto"
        position="top-center"
        toastOptions={{ className: "tw-bg-[#142645] tw-text-white" }}
      />
    </>
  );
};

function checkCurrentURLPath(path: string) {
  return /^\/s\/[0-9A-Za-z-]+\/?$/.test(path);
}

export { Chat };
